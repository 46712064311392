import axios from "axios";

export const namespaced = true;

export const state = () => ({
  loggedIn: localStorage.getItem("loggedIn") || false,
  lang: localStorage.getItem("lang") || "en",
  dialogMessage: "",
  accessFromLogin: false,
});

export const getters = {};

export const actions = {
  login({ commit }, credentials) {
    return new Promise((resolve, rejected) => {
      axios
        .post(
          `${process.env.VUE_APP_INTERNAL_SERVER_URL}/login-expertMind`,
          credentials
        )
        .then((data) => {
          const token = data.data.token;
          commit("login", token);
          commit("setAccessFromLogin", true);
          commit("setLang", credentials.lang);
          resolve();
        })
        .catch((e) => rejected(e));
    });
  },
  dispatchDialogMessage({ commit }, dialog) {
    return new Promise((resolve) => {
      commit("setDialogMessage", dialog);
      resolve();
    });
  },

  async logout({ commit }) {
    commit("logout");
  },
};

export const mutations = {
  setAccessFromLogin(state, access) {
    state.accessFromLogin = access;
  },
  setDialogMessage(state, dialog) {
    state.dialogMessage = dialog;
  },
  clearDialogMessage(state) {
    state.dialogMessage = "";
  },
  login: (state, token) => {
    localStorage.setItem("loggedIn", true);
    localStorage.setItem("token", token);

    state.loggedIn = true;
  },
  setLang(state, lang) {
    localStorage.setItem("lang", lang);
    state.lang = lang;
  },
  logout: (state) => {
    state.loggedIn = false;
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("token");
  },
};
