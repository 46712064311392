<template>
  <div>
    <!-- Bootstrap Modal -->
    <div
      class="modal fade"
      tabindex="-1"
      :class="{ show: dialogMessage }"
      :style="{ display: dialogMessage ? 'block' : 'none' }"
      @click="closeModal"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content custom-modal-content border shadow">
          <div class="modal-body text-center">
            <!-- Display the dialog message from Vuex store -->
            <p>{{ dialogMessage }}</p>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-danger fw-bold text-white"
              @click="closeModal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      dialogMessage: (state) => state.auth.dialogMessage,
    }),
  },
  methods: {
    closeModal() {
      this.$store.commit("auth/clearDialogMessage");
      this.$router.push({ name: "index" });
    },
  },
};
</script>

<style scoped>
.modal.show {
  display: block;
  transition: opacity 0.3s ease-in-out;
}

.custom-modal-content {
  border-radius: 10px;
  overflow: hidden;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.custom-modal-header {
  background-color: #007bff; /* Customize header background color */
  color: #fff;
  border-bottom: none;
}

.custom-btn {
  background-color: #007bff; /* Customize button color */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
}

.custom-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.modal-body {
  font-size: 1.1rem; /* Increase font size for readability */
  line-height: 1.6;
}

.modal-footer {
  border-top: none;
}
</style>
